<template>
  <CRow>
    <CCol md="4">
      <CCard >
        <CCardBody>
          <CCardTitle>{{ msg }}</CCardTitle>
          <CCardText>Upcoming subs</CCardText>

          <CButton color="primary" href="#" shape="">Go somewhere</CButton>
        </CCardBody>
      </CCard>

    </CCol>
    <CCol md="8">
      <CCard>
        <CCardBody>
          <VDatePicker v-model.range="range" mode="dateTime" />
        </CCardBody>
          <Calendar expanded
                    class="px-2"
                    :min-date="new Date()"
                    borderless
          />
      </CCard>
    </CCol>
  </CRow>
  <div class="dashboard">
    <h3>Test Calendar w/Highlighted ranges</h3>
    <Calendar expanded
              :attributes="calHighlights"
              :min-date="new Date()"
    />

    <VDatePicker v-model='selectedDate' />
    <h4>Selected Date: {{ selectedDate }}</h4>
  </div>
</template>

<script>
import {CCard, CCardBody, CCardTitle, CCardText, CButton, CRow, CCol} from '@coreui/vue';
import 'v-calendar/style.css';
import '@coreui/coreui/dist/css/coreui.min.css'
import {Calendar, VDatePicker} from "v-calendar";
import { ref } from 'vue';

const range = ref({
  start: new Date(2020, 0, 6),
  end: new Date(2020, 0, 10),
});

// Temp for demo
const date = new Date();
const year = date.getFullYear();
const month = date.getMonth();

export default {
  name: 'CalendarDash',
  components: {
    CRow,
    CCol,
    Calendar,
    VDatePicker,
    CCard,
    CCardBody,
    CCardTitle,
    CCardText,
    CButton
  },
  props: {
    msg: String
  },
  data() {
    return {
      selectedDate: null,
      range,
      // Attributes are supplied as an array
      calHighlights: [
        // This is a single attribute
        {
          // An optional key can be used for retrieving this attribute later,
          // and will most likely be derived from your data object
          key: 'bobsuruncle',
          // Attribute type definitions
          // content: 'red',   // Boolean, String, Object
          // highlight: true,  // Boolean, String, Object

          highlight: {
            start: { fillMode: 'outline' },
            base: { fillMode: 'light' },
            end: { fillMode: 'outline' },
          },
          // dot: true,        // Boolean, String, Object
          // bar: true,        // Boolean, String, Object
          popover: {
            label: "Bob is out, Alice is subbing",
            visibility: 'hover',
          }, // Only objects allowed
          // Your custom data object for later access, if needed
          // customData: { ... },
          // We also need some dates to know where to display the attribute
          // We use a single date here, but it could also be an array of dates,
          //  a date range or a complex date pattern.
          // dates: new Date(),
          dates: {start: new Date(year, month, 14), end: new Date(year, month, 18)},
          // Think of `order` like `z-index`
          order: 0
        },
        {
          key: 'bobsuruncle2',
          content: 'red',   // Boolean, String, Object
          highlight: {
            color: 'red',
            fillMode: 'light',
            // start: {
            //   color: 'red',
            //   fillMode: 'outline' },
            // base: { fillMode: 'light' },
            // end: { fillMode: 'outline' },
          },
          popover: {
            label: "Beth is out, Mary is subbing",
            visibility: 'hover',
          },
          dates: {start: new Date(year, month, 21), end: new Date(year, month, 23)},
          order: 0
        },
        {
          key: 'bobsuruncle3',
          content: 'green',   // Boolean, String, Object
          highlight: {
            color: 'green',
            fillMode: 'light',
            // start: {
            //   color: 'red',
            //   fillMode: 'outline' },
            // base: { fillMode: 'light' },
            // end: { fillMode: 'outline' },
          },
          popover: {
            label: "Larry is out, Bob is subbing",
            visibility: 'hover',
          },
          dates: {start: new Date(year, month, 22), end: new Date(year, month, 23)},
          order: 0
        }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
