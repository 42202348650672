<template>
  <CContainer>
    <img alt="Vue logo"
         @click="toggleDocs()"
         src="./assets/logo.png">
    <!--    Should have some header for user/menu/etc.-->
    <CalendarDash msg="SubHubEdu.io"/>
    <HelloWorld
        v-if="showDocs"
        msg="Welcome to Your Vue.js App"/>
  </CContainer>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import CalendarDash from './components/CalendarDash.vue'
import { CContainer } from '@coreui/vue'
import { ref } from 'vue';

const showDocs = ref(false);

const toggleDocs = () => {
  showDocs.value = !showDocs.value;
}


export default {
  name: 'App',
  components: {
    CalendarDash,
    HelloWorld,
    CContainer
  },
  data() {
    return {
      showDocs,
      toggleDocs
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
